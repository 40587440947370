import { render } from 'lit-html';
import store from './store.js';

export default class BElement extends HTMLElement {
    constructor() {
        super();
        this.state = {};
    }

    log(methodName) {
        return `${this.constructor.name}.${methodName}`;
    }

    connectedCallback() {
        this.unsubscribe = store.subscribe((_) => this.triggerViewUpdate());
        this.triggerViewUpdate();
    }

    disconnectedCallback() {
        this.unsubscribe();
    }

    triggerViewUpdate() {
        this.state = this.extractState(store.getState());
        const template = this.view();
        render(template, this.getRenderTarget());
    }

    getRenderTarget() {
        return this;
    }

    extractState(reduxState) {
        return reduxState;
    }

    view() {
    }
}

import OrgAdminContactMgmtClient from './OrgAdminContactMgmtClient.js';
import { clearContactFormState, setContactAddedState, setContactListState } from './OrgAdminControl.js';
import { addErrorMessage, addInformationMessage } from '../../messages/control/MessagesControl.js';

class OrgAdminContactMgmt {

    static #createAddContactRequest(mxid, displayName, start, end) {
        return {
            mxid: mxid,
            displayName: displayName,
            inviteSettings: {
                start: start,
                end: end
            }
        };
    };

    static async getContacts(base_url, access_token, user_id) {
        try {
            const client = new OrgAdminContactMgmtClient(
                base_url,
                access_token
            );
            const contactsResponse = await client.getContacts(user_id);
            setContactListState(contactsResponse.contacts);
        } catch (error) {
            console.error('Failed to fetch contacts:', error);
        }
    }

    static async addContact({
                                base_url,
                                session: { access_token, user_id }
                            },
                            { mxid, display_name, start, end }) {

        const contact = OrgAdminContactMgmt.#createAddContactRequest(mxid, display_name, start, end);
        try {
            const client = new OrgAdminContactMgmtClient(
                base_url,
                access_token
            );
            const response = await client.addContact(user_id, contact);
            setContactAddedState(response);
            addInformationMessage('Contact added');
            clearContactFormState();
        } catch (error) {
            console.error('Failed to add contact:', error);
            addErrorMessage(error);
        }
    }

    static async updateContact({
                                   base_url,
                                   session: { access_token, user_id }
                               },
                               { mxid, display_name, start, end }) {
        const contact = OrgAdminContactMgmt.#createAddContactRequest(mxid, display_name, start, end);
        try {
            const client = new OrgAdminContactMgmtClient(
                base_url,
                access_token
            );
            const response = await client.updateContact(user_id, contact);
            setContactAddedState(response);
            addInformationMessage('Contact updated');
            clearContactFormState();
        } catch (error) {
            console.error('Failed to update contact:', error);
            addErrorMessage(error);
        }
    }

    static async deleteContract({
                                    base_url,
                                    session: { access_token, user_id }
                                }, mxid) {
        try {
            const client = new OrgAdminContactMgmtClient(
                base_url,
                access_token
            );
            await client.deleteContact(user_id, mxid);
            addInformationMessage('Contact deleted');
            clearContactFormState();
        } catch (error) {
            console.error('Failed to update contact:', error);
            addErrorMessage(error);
        }
    }
}

export default OrgAdminContactMgmt;
import BElement from '../../BElement.js';
import {html} from 'lit-html';
import '../css/SecurityCheck.css';
import {resetSecurityCheckState, setSecurityCheckState} from '../control/SecurityCheckControl.js';

class SecurityCheckPassword extends BElement {
    constructor() {
        super();
        this.onContinue = (password) => {
            console.log('Default onContinue called with password:', password);
        };
        this.onCancel = () => {
            console.log('Default onCancel called');
        };
    }

    extractState(state) {
        return {
            password: state?.securityCheck?.password || '',
        };
    }

    view() {
        const label = this.getAttribute('text') || 'Security Check';

        return html`
            <div class="securityCheck">
                <div class="content-area">
                    <div class="inner">
                        <h2>${label}</h2>
                        <kosyma-input-text
                                identifier="password"
                                label="Password"
                                name="password"
                                type="password"
                                autofocus="true"
                                .value=${this.state.password || ''}
                                @keyup=${this.handleInputChange}
                        ></kosyma-input-text>
                        <kosyma-button
                                identifier="continue"
                                label="Continue"
                                @click=${this.handleContinue}
                        ></kosyma-button>
                        <kosyma-button
                                identifier="cancel"
                                label="Cancel"
                                @click=${this.handleCancel}
                        ></kosyma-button>
                    </div>
                </div>
            </div>
        `;
    }

    handleInputChange = ({target: {name, value}}) => {
        setSecurityCheckState({[name]: value});
    };

    handleContinue = () => {
        this.onContinue(this.state.password);
        resetSecurityCheckState();
    };

    handleCancel = () => {
        resetSecurityCheckState();
        this.onCancel();
    };
}

customElements.define('kosyma-securitycheck-password', SecurityCheckPassword);
import { createReducer } from '@reduxjs/toolkit';
import {
    clearContactFormAction,
    contactAddAction,
    contactAddedAction,
    contactEditAction,
    contactListAction,
    fhirSearchValueAction,
    userEditAction,
    userEditPvsCustomValueAction,
    userEditValueAction,
    userListAction
} from '../control/OrgAdminControl.js';

const initialState = {
    user_list: [],
    user_edit: null,
    fhir_search: {
        filter: '',
        result: []
    },
    contact_mgmt: {
        list: [],
        add_contact: {
            mxid: '',
            display_name: '',
            start: '',
            end: ''
        },
        added_contact: {}
    }
};

export const orgAdmin = createReducer(initialState, (builder) => {
    builder.addCase(userListAction, (state, { payload }) => {
        state.user_list = payload;
    }).addCase(userEditAction, (state, { payload }) => {
        state.user_edit = payload;
    }).addCase(userEditValueAction, (state, { payload }) => {
        state.user_edit[payload.name] = payload.value;
    }).addCase(userEditPvsCustomValueAction, (state, { payload }) => {
        state.user_edit.pvs_custom[payload.name] = payload.value;
    }).addCase(fhirSearchValueAction, (state, { payload }) => {
        state.fhir_search[payload.name] = payload.value;
    }).addCase(contactListAction, (state, { payload }) => {
        state.contact_mgmt.list = payload;
    }).addCase(contactAddAction, (state, { payload }) => {
        state.contact_mgmt.add_contact[payload.name] = payload.value;
    }).addCase(contactEditAction, (state, { payload }) => {
        state.contact_mgmt.add_contact = payload;
    }).addCase(clearContactFormAction, (state, { payload }) => {
        state.contact_mgmt.add_contact = initialState.contact_mgmt.add_contact;
    }).addCase(contactAddedAction, (state, { payload }) => {
        state.contact_mgmt.added_contact = payload;
    });
});

import { html } from 'lit-html';
import '../css/components.css';
import BElement from '../../BElement.js';

class DatePicker extends BElement {

    view() {
        const label = this.getAttribute('label');
        const name = this.getAttribute('name');
        const identifier = this.getAttribute('identifier');
        const value = this.getAttribute('value');
        const readonly = this.getAttribute('readonly');

        return html`
            <div class='form-group'>
                <input
                    type="date"
                    class='form-control'
                    id=${identifier ? identifier : ''}
                    name=${name}
                    .value=${value}
                    ?readonly=${(!!readonly)}
                    ?aria-readonly=${(!!readonly)}
                />
                <label for='${identifier}' class='form-label'> ${label} </label>
            </div>
        `;
    }
}

customElements.define('kosyma-date-picker', DatePicker);

class OrgAdminContactMgmtClient {

    #baseURL;
    #token;

    constructor(baseURL, token) {
        this.#baseURL = baseURL;
        this.#token = token;
    }

    #getHeaders(openIdToken) {
        return {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.#token}`,
            'Matrix-OpenID-Token': openIdToken
        };
    }

    async #request(endpoint, openIdToken, { method = 'GET', body = null } = {}) {
        const response = await fetch(`${this.#baseURL}${endpoint}`, {
            method,
            headers: this.#getHeaders(openIdToken),
            body: body ? JSON.stringify(body) : null
        });

        if (!response.ok) {
            const errorData = await response.json();
            if (errorData?.error) {
                throw new Error(`Error: ${errorData.error}`);
            }
            throw new Error(`Error: ${response.statusText}`);
        }
        try {
            return await response.json();
        } catch {
            return null;
        }
    }

    async #getOpenIdToken(userId) {
        const responseBody = await this.#request(`/_matrix/client/v3/user/${userId}/openid/request_token`, null, {
            method: 'POST',
            body: {}
        });
        return responseBody.access_token;
    }

    async getContacts(userId) {
        const openIdToken = await this.#getOpenIdToken(userId);
        return await this.#request(`/tim-contact-mgmt/v1.0.2/contacts/`, openIdToken);
    }

    async addContact(userId, contact) {
        const openIdToken = await this.#getOpenIdToken(userId);
        return await this.#request(`/tim-contact-mgmt/v1.0.2/contacts/`, openIdToken, {
            method: 'POST',
            body: contact
        });
    }

    async updateContact(userId, contact) {
        const openIdToken = await this.#getOpenIdToken(userId);
        return await this.#request(`/tim-contact-mgmt/v1.0.2/contacts/`, openIdToken, {
            method: 'PUT',
            body: contact
        });
    }

    async deleteContact(userId, mxid) {
        const openIdToken = await this.#getOpenIdToken(userId);
        return await this.#request(`/tim-contact-mgmt/v1.0.2/contacts/${mxid}`, openIdToken, {
            method: 'DELETE'
        });
    }
}

export default OrgAdminContactMgmtClient;
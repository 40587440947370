import { createAction } from '@reduxjs/toolkit';
import store from '../../store.js';

export const userListAction = createAction('userListAction');
export const setUserListState = state => {
    store.dispatch(userListAction(state));
};

export const orgAdminAction = createAction('orgAdminAction');
export const setOrgAdminState = state => {
    store.dispatch(orgAdminAction(state));
};

export const userEditAction = createAction('userEditAction');
export const setUserEditState = state => {
    store.dispatch(userEditAction(state));
};
export const userEditValueAction = createAction('userEditValueAction');
export const setUserEditValueState = state => {
    store.dispatch(userEditValueAction(state));
};

export const userEditPvsCustomValueAction = createAction('userEditPvsCustomValueAction');
export const userEditPvsCustomValueState = state => {
    store.dispatch(userEditPvsCustomValueAction(state));
};

export const fhirSearchValueAction = createAction('fhirSearchValueAction');
export const setFhirSearchValueState = state => {
    store.dispatch(fhirSearchValueAction(state));
};

export const contactListAction = createAction('contactListAction');
export const setContactListState = state => {
    store.dispatch(contactListAction(state));
};

export const contactAddAction = createAction('contactAddAction');
export const setContactAddState = state => {
    store.dispatch(contactAddAction(state));
};

export const contactEditAction = createAction('contactEditAction');
export const setContactEditState = state => {
    store.dispatch(contactEditAction(state));
};

export const contactAddedAction = createAction('contactAddedAction');
export const setContactAddedState = state => {
    store.dispatch(contactAddedAction(state));
};

export const clearContactFormAction = createAction('clearContactFormAction');
export const clearContactFormState = state => {
    store.dispatch(clearContactFormAction(state));
};
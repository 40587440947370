import { html } from 'lit-html';
import BElement from '../../BElement.js';
import { clearContactFormState, setContactAddState, setContactEditState } from '../control/OrgAdminControl.js';
import OrgAdminContactMgmt from '../control/OrgAdminContactMgmt.js';

class OrgAdminContactMgmtList extends BElement {
    connectedCallback() {
        super.connectedCallback();
    }

    extractState(state) {
        return {
            matrix: state.matrix,
            contact_mgmt: state.orgAdmin.contact_mgmt
        };
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>OrgAdmin - ContactMgmt</h2>
                    <table>
                        <thead>
                        <tr>
                            <th>Display Name</th>
                            <th>Matrix ID</th>
                            <th>Start</th>
                            <th>End</th>
                            <th>Active</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        ${this.state.contact_mgmt.list.map(contact => html`
                            <tr>
                                <td>${contact.displayName}</td>
                                <td>${contact.mxid}</td>
                                <td>${this.#formatDate(contact.inviteSettings.start)}</td>
                                <td>${this.#formatDate(contact.inviteSettings.end)}</td>
                                <td>
                                    ${Date.now() >= contact.inviteSettings.start && Date.now() <= contact.inviteSettings.end ? 'Yes' : 'No'}
                                </td>
                                <td>
                                    <kosyma-button
                                        identifier='edit'
                                        label='Edit'
                                        @click=${(e) => this.#editContact(e, contact)}
                                    ></kosyma-button>
                                    <kosyma-button
                                        identifier='delete'
                                        label='Delete'
                                        @click=${(e) => this.#deleteContact(e, contact)}
                                    ></kosyma-button>
                                </td>
                            </tr>
                        `)}
                        </tbody>
                    </table>
                    <div>
                        <h3>Add Contact</h3>
                        <kosyma-input-text
                            identifier='mxid'
                            label='Matrix ID'
                            value=${this.state.contact_mgmt.add_contact.mxid}
                            @keyup=${(e) => setContactAddState({ name: 'mxid', value: e.target.value })}
                        ></kosyma-input-text>
                        <kosyma-input-text
                            identifier='displayName'
                            label='Display name'
                            value=${this.state.contact_mgmt.add_contact.display_name}
                            @keyup=${(e) => setContactAddState({ name: 'display_name', value: e.target.value })}
                        ></kosyma-input-text>
                        <kosyma-date-picker
                            identifier='start'
                            label='Start'
                            value=${this.#formatDate(this.state.contact_mgmt.add_contact.start) || this.#formatDate(Date.now())}
                            @change=${(e) => setContactAddState({ name: 'start', value: e.target.valueAsNumber })}
                        ></kosyma-date-picker>
                        <kosyma-date-picker
                            identifier='end'
                            label='End'
                            value=${this.#formatDate(this.state.contact_mgmt.add_contact.end) || this.#formatDate(Date.now() + 365 * 24 * 60 * 60 * 1000)}
                            @change=${(e) => setContactAddState({ name: 'end', value: e.target.valueAsNumber })}
                        ></kosyma-date-picker>
                        <kosyma-button
                            identifier='addContact'
                            label='Save'
                            @click=${e => this.#onAddOrUpdateContact(e)}
                        ></kosyma-button>
                        <kosyma-button
                            identifier='clearForm'
                            label='Reset'
                            @click=${e => this.#onClearForm(e)}
                        ></kosyma-button>
                    </div>
                </div>
            </div>
        `;
    }

    async #onAddOrUpdateContact(e) {
        e.preventDefault();
        const isExistingContact = this.state.contact_mgmt.list.some(contact => contact.mxid === this.state.contact_mgmt.add_contact.mxid);
        if (isExistingContact) {
            await OrgAdminContactMgmt.updateContact(this.state.matrix, this.state.contact_mgmt.add_contact);
        } else {
            await OrgAdminContactMgmt.addContact(this.state.matrix, this.state.contact_mgmt.add_contact);
        }
        await OrgAdminContactMgmt.getContacts(this.state.matrix.base_url, this.state.matrix.session.access_token, this.state.matrix.session.user_id);
    }

    async #deleteContact(e, contact) {
        e.preventDefault();
        await OrgAdminContactMgmt.deleteContract(this.state.matrix, contact.mxid);
        await OrgAdminContactMgmt.getContacts(this.state.matrix.base_url, this.state.matrix.session.access_token, this.state.matrix.session.user_id);
    }

    #onClearForm(e) {
        e.preventDefault();
        clearContactFormState();
    }

    #editContact(e, contact) {
        e.preventDefault();
        setContactEditState(
            {
                mxid: contact.mxid,
                display_name: contact.displayName,
                start: contact.inviteSettings.start,
                end: contact.inviteSettings.end
            });
    }

    #formatDate(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
}

customElements.define('kosyma-orgadmin-contactmgmt-list', OrgAdminContactMgmtList);
